import { getAll } from "@/services/emailLogService";
import _ from "lodash";

const state = {
  emailLogs: null,
  emailLogsPage: 1,
  emailLogsTotal: 0,
  emailLogsPagesNumber: 0,
  emailLogsLoading: false,
};

const getters = {
  getEmailLogs: (state) => state.emailLogs,
  getEmailLogsPage: (state) => state.emailLogsPage,
  getEmailLogsTotal: (state) => state.emailLogsTotal,
  getEmailLogsPagesNumber: (state) => state.emailLogsPagesNumber,
  getEmailLogsLoading: (state) => state.emailLogsLoading,
};

const mutations = {
  setEmailLogs(state, payload) {
    state.emailLogs = _.get(payload, "data");
    state.emailLogsPage = _.get(payload, "current_page");
    state.emailLogsTotal = _.get(payload, "total");
    state.emailLogsPagesNumber = _.get(payload, "last_page");
  },
  setEmailLogsLoading(state, payload) {
    state.emailLogsLoading = payload;
  },
};

const actions = {
  async setEmailLogs({ commit }, payload) {
    try {
      commit("setEmailLogsLoading", true);
      const { data } = await getAll({
        page: _.get(payload, "page", 1),
        perPage: _.get(payload, "perPage", 10),
        franchise: _.get(payload, "franchise") || "",
        customer: _.get(payload, "customer") || "",
        student_name: _.get(payload, "student_name") || "",
        student_email: _.get(payload, "student_email") || "",
        dates: JSON.stringify(_.get(payload, "dates", [])),
        type: _.get(payload, "type") || "",
      });
      commit("setEmailLogs", data);
    } catch (error) {
      console.error(error);
    }

    commit("setEmailLogsLoading", false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
